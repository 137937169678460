import React from "react"
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap';
////import 'bootstrap/dist/css/bootstrap-grid.min.css';;

import Layout from '../../components/layout';

import * as buyStyles from './buy.module.scss';

import buyBarcode from '../../images/buy/buy-barcode@2x.jpg';
import buyBookCovers from '../../images/buy/buy-book-covers@2x.png';
import buyPhone from '../../images/buy/buy-phone@2x.png';

const BuyPage=({ location }) => {
    return(
        <>
            <Helmet>

            </Helmet>
            <Layout location={location}>
                <section>
                    <Container>
                        <Row className="py-3 py-md-5 flex-column flex-md-row align-items-center text-center text-md-left">
                            <Col>
                                <h1>Get extra copies of the books your child helped write</h1>
                                <p>Purchase extra books for relatives and friends.</p>
                                <p className="secondary-light-highlight">
                                    <span className="headingHero">£7.99</span> <span className="headingSmall fontWeight-reg">each + P&amp;P</span>
                                </p>
                                <img className={`mb-1 mt-half ${buyStyles.barcodeImg}`} src={buyBarcode} alt="" />
                                <p>Please make a note of the code on the back cover of the book you wish to order more of.</p>
                                <a className="primary-btn d-inline-flex mt-1" href={process.env.PARENT_STORE_URL}>Order books</a>
                            </Col>
                            <Col>
                                <img className={`mt-2 mt-md-0 ${buyStyles.bookCoverImg}`} src={buyBookCovers} alt="" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default BuyPage